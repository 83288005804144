import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { XpoAuthenticationGuard } from '@xpo/ngx-auth';
import { MainComponent } from './main/main.component';
import { AppRoutes } from './shared/enums/app-routes.enum';
import { PathMissingRedirectGuard } from './shared/guards/path-missing-redirect.guard';

export const RouterDefinitions: Routes = [
  {
    path: AppRoutes.MAIN_PAGE,
    component: MainComponent,
    canActivate: [XpoAuthenticationGuard],
  },
  {
    path: AppRoutes.EXCEPTION_MESSAGES,
    loadChildren: () => import('./exception-message-list/message.module').then((m) => m.MessageModule),
    canLoad: [PathMissingRedirectGuard],
  },
  {
    path: '',
    redirectTo: AppRoutes.MAIN_PAGE,
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(RouterDefinitions, { enableTracing: false })],
  exports: [RouterModule, CommonModule],
})
export class AppRoutingModule {}
