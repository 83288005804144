import { Component } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { LoginService } from '@xpo-ltl/login';
import { User } from '@xpo-ltl/sdk-common';
import { XpoAccountPopoverConfig, XpoAppSwitcherApplication, XpoFeedback, XpoShellRoute } from '@xpo/ngx-core';
import { XpoLtlAppSwitcherService, XpoLtlFeedbackService, XpoLtlReleaseNotesService } from '@xpo/ngx-ltl';
import { Observable } from 'rxjs';
import { delay, retryWhen, take } from 'rxjs/operators';
import { AccountUrls } from './shared/enums/account-urls.enum';
import { AppRoutes } from './shared/enums/app-routes.enum';
import { ConfigManagerProperties } from './shared/enums/config-manager-properties.enum';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title: string = 'AEM';
  build: string;
  apps$: Observable<XpoAppSwitcherApplication[]>;
  routes: XpoShellRoute[];
  accountPopoverConfig: XpoAccountPopoverConfig;

  constructor(
    private configManagerService: ConfigManagerService,
    public feedbackService: XpoLtlFeedbackService,
    private releaseNotesService: XpoLtlReleaseNotesService,
    private loginService: LoginService,
    private dialog: MatDialog,
    private appSwitcherService: XpoLtlAppSwitcherService
  ) {
    this.routes = [
      {
        label: 'Main',
        path: AppRoutes.MAIN_PAGE,
      },
    ];

    /** Shell setup */
    this.title = configManagerService.getSetting<string>(ConfigManagerProperties.appNames);
    this.build = configManagerService.getSetting<string>(ConfigManagerProperties.buildVersion);

    /** Initialize SIC switcher by getting logged in user's sic */
    this.handleLoggedInUser();
    this.loginService.userLoggedIn$.subscribe(() => {
      this.handleLoggedInUser();
    });
  }
  private static getProfilePictureUrl(email: string): string {
    return `${AccountUrls.switchApiUrl}${email}${AccountUrls.pictureUrl}`;
  }

  handleFeedbackClick(): void {
    this.feedbackService.feedbackConfig$.pipe(take(1)).subscribe((config) => {
      this.dialog.open(XpoFeedback, { data: config });
    });
  }

  handleReleaseNotesClick(): void {
    this.releaseNotesService.showReleaseNotes().subscribe(() => {});
  }

  private handleLoggedInUser(): void {
    this.loginService
      .getLoggedInUser(this.configManagerService.getSetting(ConfigManagerProperties.loggedInUserRoot))
      .pipe(retryWhen((errors) => errors.pipe(delay(1000), take(5))))
      .subscribe(
        (user: User) => {
          if (user) {
            this.populateAccountPopover(user);
            // this.populateAppSwitcher();
          }
        },
        (error) => {
          console.log('ERROR', error);
        }
      );
  }

  private populateAccountPopover(user: User): void {
    const fullName = `${user.givenName} ${user.lastName}`;

    this.accountPopoverConfig = {
      imageUri: AppComponent.getProfilePictureUrl(user.emailAddress),
      name: fullName,
      onSignOutCallback: (): void => {
        this.signOut();
      },
      links: [
        { title: 'My Account', url: AccountUrls.myAccount },
        { title: 'Help', url: AccountUrls.help },
        { title: 'Privacy Policy', url: AccountUrls.privacyPolicy },
      ],
    };
  }

  private signOut(): void {
    // Removing the local storage keys
    this.loginService.clear();
  }

  private populateAppSwitcher(): void {
    this.apps$ = this.appSwitcherService.getAppList();
  }
}
