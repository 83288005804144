import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule, MatIconModule, MatInputModule } from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { AemApiService } from '@xpo-ltl/sdk-aem';
import { XpoButtonModule, XpoCardModule, XpoConfirmDialogModule, XpoDialogModule, XpoIconModule } from '@xpo/ngx-core';
import { XpoAgGridModule } from '@xpo/ngx-core-ag-grid';
import { XpoAgGridBoardModule } from '@xpo/ngx-core-board-ag-grid';
import { AgGridModule } from 'ag-grid-angular';
import { SharedModule } from '../shared/shared.module';
import { MainDialogComponent } from './dialog/main-dialog.component';
import { MainComponent } from './main.component';
import { ButtonRendererComponent } from './renderer/button-renderer.component';

@NgModule({
  declarations: [MainComponent, MainDialogComponent, ButtonRendererComponent],
  imports: [
    AgGridModule.withComponents([ButtonRendererComponent]),
    BrowserModule,
    CommonModule,
    FlexModule,
    FormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    XpoAgGridBoardModule,
    XpoAgGridModule,
    XpoButtonModule,
    XpoCardModule,
    XpoConfirmDialogModule,
    XpoDialogModule,
    XpoIconModule,
    SharedModule,
  ],
  entryComponents: [MainDialogComponent],
  providers: [AemApiService],
})
export class MainModule {}
