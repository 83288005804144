import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material';
import {
  ActionBarCenterContent,
  ActionBarComponent,
  ActionBarLeftContent,
  ActionBarRightContent,
} from './components/action-bar/action-bar.component';
import { MessageTooltipComponent } from './components/grid-tooltip/message-tooltip.component';
import { NoSpaceOnlyDirective } from './directives/no-space-only.directive';

@NgModule({
  declarations: [
    // Components
    ActionBarComponent,
    ActionBarLeftContent,
    ActionBarCenterContent,
    ActionBarRightContent,
    NoSpaceOnlyDirective,
    MessageTooltipComponent,
    // Pipes/Directives
  ],
  imports: [
    MatTooltipModule,
    CommonModule,
    // Pipes/Directives
  ],
  exports: [
    // Components
    ActionBarComponent,
    ActionBarLeftContent,
    ActionBarCenterContent,
    ActionBarRightContent,
    NoSpaceOnlyDirective,
    // Pipes/Directives
  ],
  // providers:  MAKE providedIn: 'root' on your services!!!
})
export class SharedModule {}
