import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  AemApiService,
  Component as AEMComponent,
  CreateAemComponentResp,
  CreateAemComponentRqst,
  ListAemComponentsQuery,
  UpdateAemComponentResp,
  UpdateAemComponentRqst,
} from '@xpo-ltl/sdk-aem';
import { ListInfo } from '@xpo-ltl/sdk-common';
import { XpoSnackBar } from '@xpo/ngx-core';
import { GridApi } from 'ag-grid-community';
import { ActionEnum } from '../shared/enums/actions.enum';
import { AppRoutes } from '../shared/enums/app-routes.enum';
import { SnackbarNotifications } from '../shared/enums/snackbar-status.enum';
import { MainDialogComponent } from './dialog/main-dialog.component';
import { ButtonRendererComponent } from './renderer/button-renderer.component';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MainComponent implements OnInit {
  columnDefs: any;
  rowData: AEMComponent[];
  selectionMode: 'row' | 'cell' = 'row';
  selectedRow: any = null;
  searchValue: string;
  dialogTitles = {
    add: 'Add Component',
    update: 'Update Component',
  };

  private gridApi: GridApi;

  constructor(
    private aemService: AemApiService,
    private dialog: MatDialog,
    private router: Router,
    private xpoSnackBar: XpoSnackBar
  ) {
    this.columnDefs = [
      {
        headerName: 'Code',
        field: 'componentCd',
        width: 40,
        resizable: true,
        cellRendererFramework: ButtonRendererComponent,
        cellStyle: { 'border-left': 'none' },
        sortable: true,
      },
      { headerName: 'Description', field: 'componentDescription', minWidth: 100, resizable: true, sortable: true },
    ];
    this.rowData = new Array<AEMComponent>();
  }

  ngOnInit() {
    const listAemComponentsQuery = new ListAemComponentsQuery();
    listAemComponentsQuery.listInfo = new ListInfo();
    listAemComponentsQuery.listInfo.numberOfRows = 10000;
    this.aemService.listAemComponents(listAemComponentsQuery).subscribe((response) => {
      this.rowData = response.components;
    });
  }

  onGridReady(params: any): void {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    const sort = [{ colId: 'componentCd', sort: 'asc' }];
    this.gridApi.setSortModel(sort);
  }

  quickSearch() {
    this.gridApi.setQuickFilter(this.searchValue);
  }

  onRowClicked(row: any) {
    this.selectedRow = row;
  }

  openDialog(title: string, mode: string, data?: any): void {
    const dialogRef = this.dialog.open(MainDialogComponent, {
      width: '400px',
      data: {
        mode: mode,
        title: title,
        params: data,
      },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (!result) {
        this.clearSelection();
        return;
      }

      switch (result.mode) {
        case ActionEnum.ADD:
          const requestAdd: CreateAemComponentRqst = {
            componentCd: result.code,
            componentDescription: result.description,
          };
          this.aemService.createAemComponent(requestAdd).subscribe(
            (response: CreateAemComponentResp) => {
              this.gridApi.updateRowData({ add: [response.component] });
              this.router.navigate([AppRoutes.EXCEPTION_MESSAGES, response.component.componentCd]);
            },
            (error) => {
              console.log(error);
              this.xpoSnackBar.error(SnackbarNotifications.ADD_COMPONENT_FAILED);
            },
            () => {
              this.xpoSnackBar.success(SnackbarNotifications.ADD_COMPONENT_SUCCESS);
              this.clearSelection();
            }
          );
          break;

        case ActionEnum.UPDATE:
          const requestUpdate: UpdateAemComponentRqst = {
            componentCd: result.code,
            componentDescription: result.description,
          };
          this.aemService.updateAemComponent(requestUpdate).subscribe(
            (response: UpdateAemComponentResp) => {
              const rowIndex = this.rowData.findIndex(
                (element) => element.componentCd === response.component.componentCd
              );
              this.rowData[rowIndex].componentDescription = response.component.componentDescription;
              this.gridApi.redrawRows();
            },
            (error) => {
              console.log(error);
              this.xpoSnackBar.error(SnackbarNotifications.UPDATE_COMPONENT_FAILED);
            },
            () => {
              this.xpoSnackBar.success(SnackbarNotifications.UPDATE_COMPONENT_SUCCESS);
              this.clearSelection();
            }
          );
          break;
      }
    });
  }

  clearSelection() {
    this.gridApi.clearFocusedCell();
    this.selectedRow = null;
  }

  actionClicked(action: any) {
    switch (action) {
      case ActionEnum.ADD:
        const addParams: any = {
          rowData: this.rowData,
        };
        this.openDialog(this.dialogTitles.add, action, addParams);
        break;

      case ActionEnum.UPDATE:
        const updateParams: any = {
          componentCode: this.selectedRow.data.componentCd,
          description: this.selectedRow.data.componentDescription,
        };
        this.openDialog(this.dialogTitles.update, action, updateParams);
        break;
    }
  }
}
