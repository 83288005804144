import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material';
import { MatFormFieldModule, MatInputModule } from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { XpoConfirmDialogModule, XpoDialogModule } from '@xpo/ngx-core';
import { XpoButtonModule } from '@xpo/ngx-core';
import { NotAuthorizedComponent } from './not-authorized/not-authorized.component';

const dialogs = [
  // YOUR DIALOGS
  NotAuthorizedComponent,
];

@NgModule({
  declarations: [...dialogs],
  imports: [
    // MATERIAL OR NGX CORE COMPONENTS
    XpoDialogModule,
    XpoConfirmDialogModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    XpoButtonModule,
    BrowserModule,
    FormsModule,
  ],
  exports: [...dialogs],
  entryComponents: [...dialogs],
})
export class DialogModule {}
