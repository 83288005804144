import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component as AEMComponent } from '@xpo-ltl/sdk-aem';
import { ActionEnum } from '../../shared/enums/actions.enum';

@Component({
  selector: 'app-dialog',
  templateUrl: './main-dialog.component.html',
  styleUrls: ['./main-dialog.component.scss'],
})
export class MainDialogComponent {
  title: string = '';
  code: string = '';
  description: string = '';
  mode: string = '';
  isUpdateDialog: boolean = false;
  existentCode: AEMComponent = null;
  rowData: AEMComponent[];
  maxChars: number = 100;
  remainingChars: number = 100;
  specChars: string = '- _ @ # \' : , . [ ] ( )';

  constructor(public dialogRef: MatDialogRef<MainDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: any) {
    this.title = data.title;

    switch (data.mode) {
      case ActionEnum.ADD:
        this.mode = data.mode;
        this.rowData = data.params.rowData;
        break;

      case ActionEnum.UPDATE:
        this.mode = data.mode;
        this.code = data.params.componentCode;
        this.description = data.params.description;
        this.isUpdateDialog = true;
        break;
    }
  }

  checkCodeExistence() {
    this.code = this.code.toUpperCase();

    if (this.code.length === 3) {
      this.existentCode = this.rowData.find((itemData) => {
        return itemData.componentCd.toUpperCase() === this.code.toUpperCase();
      });
    } else {
      this.existentCode = null;
    }
  }

  countChars(desc: string) {
    const len = desc.trim().length;
    this.remainingChars = this.maxChars - len;
  }

  save(): void {
    if (!this.existentCode) {
      const saveRqst = {
        code: this.code.toUpperCase(),
        description: this.description.trim(),
        mode: this.mode,
      };
      this.dialogRef.close(saveRqst);
    }
  }
}
