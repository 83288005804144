import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { AppRoutes } from '../../shared/enums/app-routes.enum';

@Component({
  selector: 'app-button-renderer',
  templateUrl: './button-renderer.component.html',
  styles: [],
})
export class ButtonRendererComponent implements ICellRendererAngularComp {
  params: any;
  label: string;

  constructor(private router: Router) {}

  agInit(params: any): void {
    this.params = params;
  }

  refresh(): boolean {
    return true;
  }

  onClick(params: any) {
    this.router.navigate([AppRoutes.EXCEPTION_MESSAGES, params]);
  }
}
