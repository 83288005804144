import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AppRoutes } from '../enums/app-routes.enum';

@Injectable({
  providedIn: 'root',
})
export class PathMissingRedirectGuard implements CanActivate, CanLoad {
  constructor(private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const code = next.params.code;
    if (!code) {
      this.router.navigate([AppRoutes.MAIN_PAGE]);
      return false;
    }
    return true;
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    if (segments.length === 1) {
      this.router.navigate([AppRoutes.MAIN_PAGE]);
      return false;
    }
    return true;
  }
}
